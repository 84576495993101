import React from 'react';
import "./video-wrapper.css";

const VideoWrapper = (props) => {
    return (
        <>
            <div className="widget-result-heading">
                <p className="widget-result-title">
                    Set up '{props?.result?.value}' on the scale with the pump lid in fully upright position having the lid lightly pressed by one hand against end stop
                </p>
                <p className="widget-result-text">
                    Please scroll the slider until the pointer
                    shows {props?.result?.value} at tube holder scale
                </p>
            </div>
            <div className="widget-iframe-container">
                <iframe
                    src="https://www.youtube.com/embed/VfdTcZX39bI?si=auMGR4ivpITbI3mD"
                    title="ETL500 Easy Tube Loading"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
            </div>
        </>
    );
};

export default VideoWrapper;